import React, { useRef, useState } from 'react'
import { Link, navigate } from 'gatsby'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { graphql } from 'gatsby'
import BeatLoader from 'react-spinners/BeatLoader'
import { Controller, useForm } from 'react-hook-form'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'

import encode from '../helpers/encode'

import '../styles/templates/apply.scss'

const CssTextField = withStyles({
  root: {
    width: '100% !important',
    '& .MuiInput-formControl': {
      marginBottom: 0,
    },
    '& label': {
      fontSize: 20,
      color: '#8d999c',
      top: -9,
    },
    '& label.Mui-focused': {
      color: '#22738d',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#22738d',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#f44336',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#22738d',
      },
    },
    '@media (max-width: 768px)': {
      '& label': {
        fontSize: 16,
      },
    },
  },
})(TextField)
const StyleFormControl = withStyles({
  root: {
    '& .MuiTypography-root': {
      fontSize: 12,
      fontWeight: 'bold',
    },
  },
})(FormControlLabel)

export const Content = ({ title, path }) => {
  const [state, setState] = React.useState({})
  const [file, setFile] = React.useState()
  const { register, handleSubmit, control, errors } = useForm()

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    setIsLoading(true)
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        title,
        CV: file,
        ...state,
      }),
    })
      .then(() => {
        navigate(form.getAttribute('action'), {
          state: { isSuccess: true },
        })
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const [isLoading, setIsLoading] = useState(false)

  const fileRef = useRef()

  const CheckboxCustom = withStyles({
    root: {
      color: '#1d1c1a',
      '&$checked': {
        color: '#22738d',
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />)

  return (
    <section className="content-wrapper max-w-screen-xl mx-auto lg:grid grid-cols-10 mb-16 md:px-16 px-6">
      <div className="col-start-1 col-end-3 flex lg:block justify-between items-start ">
        <div>
          <h2 style={{ color: '#22738d' }} className="font-normal text-2xl md:text-4xl ">
            {title}
          </h2>
          <hr className="mb-10 " style={{ borderColor: '#f9d16d', width: 158 }} />
        </div>

        <Link to={`/jobs/${path}`} className="flex items-center lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" className="mr-3">
            <g fill="none" fillRule="evenodd">
              <g stroke="#27393D">
                <g>
                  <path d="M0 4.5L10 4.5M6 0L10 4.5 6 9" transform="translate(-121 -266) rotate(-180 66 138)" />
                </g>
              </g>
            </g>
          </svg>
          Back
        </Link>
      </div>
      <div style={{ fontSize: 15, lineHeight: '30px', maxWidth: 491 }} className="col-start-4 col-end-9 mx-auto">
        <h6 style={{ fontSize: 10 }} className="mb-16">
          YOUR DETAILS
        </h6>
        <form
          name="Job Apply"
          method="post"
          action="/jobs/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" name="form-name" value="Job Apply" />
          <input type="hidden" name="title" value={title} />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <div className="mb-12">
            <CssTextField
              label="Your Name"
              name="name"
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors && errors.name && errors.name.message}
              inputRef={register({
                required: 'This field is required',
                pattern: {
                  value: /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/,
                  message: 'Please enter valid name',
                },
              })}
            />
          </div>
          <div className="mb-12">
            <CssTextField
              label="Email"
              name="email"
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors && errors.email && errors.email.message}
              inputRef={register({
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Please enter valid email address',
                },
              })}
            />
          </div>
          <div className="mb-14">
            <CssTextField
              label="Phone"
              name="phone"
              onChange={handleChange}
              error={Boolean(errors.phone)}
              helperText={errors && errors.phone && errors.phone.message}
              inputRef={register({
                required: 'This field is required',
                pattern: {
                  value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                  message: 'Please enter valid phone number',
                },
              })}
            />
          </div>
          <div className="mb-10 ">
            <input
              name="CV"
              type="file"
              id="upload-btn"
              hidden
              onChange={() => setFile(fileRef.current.files[0])}
              ref={e => {
                register(e, {
                  required: true,
                  validate: files => {
                    const file = files[0]
                    const ext = file.name.split('.').pop()
                    const isAcceptableExt = ext === 'pdf' || ext === 'docx' || ext === 'doc'
                    const isAcceptableSize = file.size <= 5242880
                    return (
                      (isAcceptableExt && isAcceptableSize) ||
                      'Accepted file formats: .pdf, .docx, .doc of maximum 5MB '
                    )
                  },
                })
                fileRef.current = e
              }}
            />
            <label
              style={{
                height: 54,
                border: 'solid 1px',
                borderColor: errors && errors.CV ? '#f44336' : '#27393d',
                fontSize: 13,
                letterSpacing: '1.86px',
                cursor: 'pointer',
              }}
              className="flex items-center justify-center font-bold"
              htmlFor="upload-btn"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" className="mr-3">
                <path
                  fill="none"
                  stroke="#27393D"
                  strokeWidth="1.5"
                  d="M5.5 0L5.5 11M11 5.5L0 5.5"
                  transform="translate(-618 -554) translate(618 551) translate(0 3.5)"
                />
              </svg>
              UPLOAD YOUR CV/RESUME
            </label>
            <p>{file && file.name}</p>
            <p style={{ color: '#f44336' }} className="text-xs">
              {errors && errors.CV && errors.CV.message}
            </p>
          </div>
          <div className="mb-12">
            <p style={{ lineHeight: 1.54 }} className="text-xs font-light">
              Individuals seeking employment at this company are considered without regards to race, color, religion,
              national origin, age, sex, marital status, ancestry, physical or mental disability, veteran status, gender
              identity, or sexual orientation. You are being given the opportunity to provide the following information
              in order to help us comply with federal and state Equal Employment Opportunity/Affirmative Action record
              keeping, reporting, and other legal requirements. <br /> <br /> Completion of the form is entirely
              voluntary. Whatever your decision, it will not be considered in the hiring process or thereafter. Any
              information that you do provide will be recorded and maintained in a confidential file.
              <br /> <br />
              Before submitting this application you must agree to our{' '}
              <Link to="/terms-and-conditions" className="font-bold">
                Terms and Conditions
              </Link>{' '}
              and the{' '}
              <Link to="/privacy-and-cookie-policy" className="font-bold">
                Privacy and cookie policy
              </Link>
              .
            </p>
            <div className="flex items-center justify-center md:block">
              <StyleFormControl
                // inputRef={register({
                //   validate: () => watch("agree").length,
                // })}
                control={
                  // <CheckboxCustom
                  //   name="agree"
                  //   value="agree"
                  //   checked={agree}
                  //   onChange={e => setAgree(!agree)}
                  //   inputRef={register()}
                  //   // inputRef={register({
                  //   //   validate: () => !!getValues()["test"].length,
                  //   // })}
                  // />
                  <Controller
                    name="agree"
                    control={control}
                    defaultValue={false}
                    rules={{
                      required: 'Please accept Terms and Conditions and Privacy and cookie policy',
                    }}
                    render={props => (
                      <CheckboxCustom onChange={e => props.onChange(e.target.checked)} checked={props.value} />
                    )}
                  />
                }
                label="I agree"
              />
            </div>
            <p style={{ color: '#f44336' }} className="text-xs">
              {errors && errors.agree && errors.agree.message}
            </p>
          </div>
          <div className="text-center">
            <button
              disabled={isLoading}
              style={{
                backgroundColor: '#1d1c1a',
                fontSize: 13,
                letterSpacing: '1.86px',
                width: 240,
                height: 54,
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {!isLoading ? 'SUBMIT APPLICATION' : <BeatLoader color="white" loading={true} size={16} />}
            </button>
          </div>
        </form>
      </div>
      <div className="col-start-11 col-end-12">
        <Link to={`/jobs/${path}`} className="lg:flex items-center hidden">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" className="mr-3">
            <g fill="none" fillRule="evenodd">
              <g stroke="#27393D">
                <g>
                  <path d="M0 4.5L10 4.5M6 0L10 4.5 6 9" transform="translate(-121 -266) rotate(-180 66 138)" />
                </g>
              </g>
            </g>
          </svg>
          Back
        </Link>
      </div>
    </section>
  )
}

const Legal = ({ data: graphqlData, location }) => {
  const { contentNode, pageTitle: title } = graphqlData.datoCmsLegal
  const { html } = contentNode.childMarkdownRemark
  return (
    <Layout className="pt-48" pathname={location.pathname} simpleHeader alwaysBlack>
      <SEO title={title} />
      <div
        className="max-w-3xl mx-auto text-tuya-rho font-poppins legal p-8 lg:p-0 leading-8"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsLegal(id: { eq: $id }) {
      pageTitle
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Legal
